<template>
  <div>
    <van-cell-group title="筛选">
      <van-dropdown-menu>
        <van-dropdown-item v-model="sea.STA" @change="filter" :options="[
            {text:'全部状态', value:0},
            {text:'新申请', value:1},
            {text:'已撤消', value:2},
            {text:'备修中', value:5},
            {text:'已修好', value:7},
            {text:'已评价', value:9}]" />
      </van-dropdown-menu>
      <van-search v-model="sea.KEY" placeholder="请输入搜索关键字"  @search="filter" @clear="filter"/>
      <van-field readonly label="名称" placeholder="选择分类筛选" is-link @click="cat.show=true" v-model="cat.txt" />
      <van-popup v-model="cat.show" round  position="bottom">
        <van-cascader title="请选择分类" :options="cat.ls" @finish="catSel" @close="cat.show=false" v-model="sea.CAT_ID"
                      :field-names="{text:'NAME',value:'ID',children:'CHILDREN'}" />
      </van-popup>
      <van-cell title="日期" :value="date.val" @click="date.show=true" is-link/>
      <van-calendar v-model="date.show" type="range" @confirm="dateSel" :show-confirm="false" :allow-same-day="true" :min-date="new Date('2021/7/1')" />
    </van-cell-group>
    <van-divider />
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading" >
      <div v-for="item in lst.da" :key="item.ID" class="card">
        <van-row :gutter="8" style="line-height: 1.8em">
          <van-col :span="10" style="text-align: center">
            <van-image :src="item.PIC_URL" v-if="item.PIC_NAME" @click="prvImage(item.PIC_URL,item.PIC_URL_FINISH)"/>
            <van-icon name="photo-o" v-else size="80px" />
          </van-col>
          <van-col :span="14" >
            <div><b>编号:</b>{{item.SN}} <van-icon v-if="item.STA==1" name="hot-o" color="#ff0000" size="1.5rem"/></div>
            <div><b>位置:</b>{{item.LOC_NAME}}</div>
            <div><b>名称:</b>{{item.CAT_NAME}}</div>
            <div><b>提交人:</b>{{item.SUB_NAME}}</div>
            <div v-if="item.STA>=7">
              <!-- <div><b>处理人:</b>{{item.FIX_NAME}}</div> -->
              <div><b>维修人:</b>{{item.MENDER}}</div>
            </div>
          </van-col>
        </van-row>
        <van-divider />
        <div><b>问题:</b>{{item.DESCRIPTION}}</div>
        <div style="margin-top: 10px;" v-if="item.MEM"><b>备注:</b>{{item.MEM}}</div>
        <van-divider />
        <van-row :gutter="10" style="text-align: center; color:#3aa8ec">
          <van-col :span="8">
            <span style="color:#ccc; display: block">状态</span>
            {{item.STA_TXT}}
          </van-col>
          <van-col :span="8">
            <div v-if="item.STA>=7">
              <span style="color:#ccc; display: block">处理日期</span>
              {{item.FINISH_DT|datetime('YYYY-MM-DD')}}
            </div>
            <div v-else>
              <span style="color:#ccc; display: block">提交日期</span>
              {{item.CREATED_DT|datetime('YYYY-MM-DD')}}
            </div>
          </van-col>
          <van-col :span="8">
            <div v-if="item.STA==9">
              <span style="color:#ccc; display: block">评价</span>
              <van-rate v-model="item.RATE" count="3" readonly color="#ffd21e"/>
            </div>
            <div v-else-if="item.STA==7 &&  sea.FOR=='MINE'">
              <van-button plain type="primary" round @click="rating(item.ID)">去评分</van-button>
            </div>
            <div v-else-if="(item.STA==1 || item.STA==5 )">
<!--              <van-button v-if="sea.FOR=='PRO'" plain type="primary" round @click="processing(item.ID)">去处理</van-button>-->
              <van-button v-if="sea.FOR=='MINE'" plain type="info" round @click="cancel(item.ID)">撤消</van-button>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-list>
    <div>
      <van-dialog v-model="rate.show" show-cancel-button @confirm="rated">
        <div style="text-align: center">
          <p>请给这次维修结果打分</p>
          <van-rate v-model="rate.score" count="3" />
        </div>
      </van-dialog>
<!--      <van-dialog v-model="pro.show" :show-confirm-button="false" title="维修结果">-->
<!--        <div style="text-align: center">-->
<!--          <van-field type="number" label="金额" v-model="pro.cost" />-->
<!--          <van-field type="text" label="维修人" v-model="pro.mender" placeholder="可以直接选择以下人员" />-->
<!--          <div class="mender" v-if="menders.length>0">-->
<!--            <van-tag type="success" size="large" v-for="it in menders" :key="it" @click="pro.mender=it">{{ it }}</van-tag>-->
<!--          </div>-->
<!--          <div style="text-align: left">-->
<!--            <van-cell-group title="上传图片">-->
<!--              <van-uploader :after-read="afterRead" v-model="pro.picList" max-count="1" style="margin: 10px 0px 0px 10px"/>-->
<!--            </van-cell-group>-->
<!--          </div>-->
<!--          <van-field type="textarea" v-model="pro.mem" placeholder="请输入处理的结果信息" label="备注" maxlength="100" show-word-limit />-->
<!--          <div class="pro">-->
<!--            <van-button type="default" icon="cross" @click="pro.show=false">取消</van-button>-->
<!--            <van-button type="primary" icon="underway-o" @click="update(5)">备修</van-button>-->
<!--            <van-button type="info" icon="success" @click="update(7)">完成</van-button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </van-dialog>-->
    </div>
  </div>
</template>
<style scoped>
.card {margin: 10px; border-radius: 15px; background: #fff;padding: 10px;font-size: 14px;}
.card div b {color: #999; font-weight: normal; margin-right: 5px}
.pro {padding: 5px}
.pro button { margin: 0px 5px; width: 90px;}
.mender {border-bottom: solid 1px #ebedf0; text-align: left; padding-left: 5px;}
.mender span { margin: 5px;}
</style>
<script>
import { ImagePreview,Dialog } from 'vant';
import moment from "moment";
export default {
  components:{[ImagePreview.Component.name]: ImagePreview.Component},
  data(){
   return{
     sea:{
       PAGE_SIZE:5,
       PAGE_INDEX:-1,
       FOR:this.$route.query.for,
       STA:this.$route.query.sta
     },
     lst:{
       da:[],
       loading:false,
       finish:false
     },
     cat:{
       show:false,
       ls:[],
       txt:""
     },
     date:{
       show:false,
       val:""
     },
     rate:{
       show:false,
       score:0,
       id:0
     },
     pro:{
       show:false,
       id:0,
       mem:"",
       mender:"",
       cost:0,
       picList:[],
     },
     menders:[]
   }
  },
  created() {
    this.getCat();
    this.getMenders();
  },
  methods:{
    getCat(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/FIX/CatApi/GetTree",
        completed: function (its) {
          self.cat.ls=[{ID:0,NAME:"全部",LEAF:true}]
          self.cat.ls.push(...its.DATA);
        }
      })
    },
    getMenders(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/FIX/NoteApi/GetMenders",
        completed: function (its) {
          self.menders=its.DATA;
        }
      })
    },
    catSel({ selectedOptions}){
      this.cat.show=false;
      this.cat.txt = selectedOptions.map((option) => option.NAME).join('/');
      this.filter();
    },
    dateSel(d){
      this.sea.DTS=moment(d[0]).format('YYYY-MM-DD');
      this.sea.DTE=moment(d[1]).format('YYYY-MM-DD')
      this.date.show=false;
      this.date.val=this.sea.DTS+' 至 '+this.sea.DTE;
      this.filter();
    },

    filter(){
      this.lst.finish=true;
      this.lst.da.length=0;
      this.lst.finish=false;
      this.sea.PAGE_INDEX=0;
      this.getList();
    },
    loadPage(){
      this.sea.PAGE_INDEX+=1;
      this.getList();
    },
    getList(){
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/Mobile/FIX/NoteApi/GetList",
        data:this.sea,
        completed(its,n){
          self.lst.da.push(...its.ITEMS);
          self.lst.loading=false;
          if (self.lst.da.length>=n) self.lst.finish=true;
        }
      })
    },
    prvImage(url1,url2){
      let us=[];
      if (url1!=undefined) us.push(url1)
      if (url2!=undefined) us.push(url2)
      ImagePreview(us)
    },

    rating(id){
      this.rate={id:id,show:true,score:0}
    },
    rated(){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/Mobile/FIX/NoteApi/Rate",
        data:{
          ID:this.rate.id,
          RATE:this.rate.score
        },
        finally(){},
        completed(){
          self.rate.show=false;
          let n=self.lst.da.length;
          for(let i=0;i<n;i++){
            let o=self.lst.da[i];
            if (o.ID==self.rate.id) {
              o.STA=9;
              o.RATE=self.rate.score;
              break;
            }
          }
        }
      })
    },
    // processing(id){
    //   Object.assign(this.pro,{id:id,show:true,mem:"",mender:''})
    // },
    // update(sta){
    //   let n=this.pro.picList.length>0;
    //   if (n>0) {
    //     this.pro.PIC_B64=this.pro.picList[0].content;
    //     let arr=this.pro.PIC_B64.split(',')
    //     this.pro.PIC_B64=arr[1];
    //   }
    //   let self=this;
    //   this.whale.remote.getResult({
    //     url:"/fix/note/update",
    //     data:{
    //       ID:this.pro.id,
    //       MEM:this.pro.mem,
    //       MENDER:this.pro.mender,
    //       COST:this.pro.cost,
    //       PIC_B64:this.pro.PIC_B64,
    //       STA:sta,
    //     },
    //     finally(){},
    //     completed(m){
    //       self.pro.show=false;
    //       let n=self.lst.da.length;
    //       for(let i=0;i<n;i++){
    //         let o=self.lst.da[i];
    //         if (o.ID==self.pro.id) {
    //           //o.STA=sta;
    //           Object.assign(o,{STA:m.STA,STA_TXT:m.STA_TXT,MEM:m.MEM,MENDER:m.MENDER,FIX_NAME:m.FIX_NAME,FINISH_DT:m.FINISH_DT});
    //           //o.MEM=self.pro.mem;
    //           break;
    //         }
    //       }
    //     }
    //   })
    // },
    cancel(id){
      let self=this;
      Dialog.confirm({
        title:"撤消维修",
        message:"真的要撤消这次维修吗？",
      }).then(()=>{
        this.whale.remote.getResult({
          url:"/api/Mobile/FIX/NoteApi/Cancel",
          data:{
            ID:id,
          },
          finally(){},
          completed(){
            self.filter();
          }
        })
      })
    },
    // afterRead(file){
    //   this.pro.picList=[file];
    // },
  }
}
</script>

